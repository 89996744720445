import axios from 'axios';
import authHeader from './auth-header';
// export const API_URL = 'https://dev.custodiy.com/api/';
export const API_URL = 'https://admin.custodiy.com/api/';

const register = (firstName, lastName, email, password) => axios.post(`${API_URL}register`, {
  firstName, lastName, email, password,
  // confirmPassword
});
const verifyOtp = (otp, email) => axios.post(`${API_URL}varifyOtp`, {
  otp, email,
});
const resendOtp = email => axios.post(`${API_URL}resendOtp`, {
  email,
});
const forgetPass = email => axios.post(`${API_URL}forgotPassword`, {
  email,
});
const changePassword = (password, newPassword) => axios.post(`${API_URL}changePassword`, {
  password, newPassword,
}, { headers: authHeader() });
const login = async (email, password) => axios
  .post(`${API_URL}login`, {
    email,
    password,
    role:'admin'
  })
  .then((response) => {
    sessionStorage.setItem('user', JSON.stringify(response.data));

    if (response.data.token) {
      sessionStorage.setItem('user', JSON.stringify(response.data));
    }

    return response.data;
  });

const logout = () => {
  sessionStorage.removeItem('user');
};
export const sendToken = async (amount, receiver, coin, otp) => axios
  .post(`${API_URL}sendToken`, {
    amount,
    receiver,
    coin,
    otp,
  }, { headers: authHeader() });
const varifyPasswordotp = (email, otp) => axios.post(`${API_URL}varifyPasswordotp`, {
  email, otp,
});
const resetPassword = (email, otp, password, confirmPassword) => axios.post(`${API_URL}resetPassword`, {
  email, otp, password, confirmPassword,
});
const verifyTwoFactor = (otp, email) => axios.post(`${API_URL}varify_2fa_otp`, {
  otp, email,
}, { headers: authHeader() })
  .then((response) => {
    sessionStorage.setItem('user', JSON.stringify(response.data));

    if (response.data.token) {
      sessionStorage.setItem('user', JSON.stringify(response.data));
    }

    return response.data;
  });
export default {
  register,
  login,
  forgetPass,
  logout,
  verifyOtp,
  resendOtp,
  // sendToken,
  changePassword,
  resetPassword,
  varifyPasswordotp,
  verifyTwoFactor,
};
