import React, { useEffect } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {
  colorBorder,
  colorBackground,
  colorHover,
  colorDustyWhite,
  colorAccent,
} from "@/utils/palette";
import { FaBitcoin } from "react-icons/fa";
import { left } from "@/utils/directions";
import SidebarLink, { SidebarLinkTitle } from "./SidebarLink";
import SidebarCategory from "./SidebarCategory";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { far } from "@fortawesome/free-regular-svg-icons";
import { useChainContext } from "../../../context/context";
import { toast } from "react-toastify";
import io from "socket.io-client";
import config from "../../../../config/config";

const SidebarContent = ({ onClick, collapse }) => {
  const { setSocket } = useChainContext()
  const socket = io(config.baseURL);
  useEffect(() => {
    setSocket(socket)
    socket.on("connect", () => {
      socket.on("connection", () => {
      });
      socket.emit(
        "connected",
        {
          subject: "testing",
          user_id_2: "64d200a15a0ec71ba4278c9f",
          user_id: JSON.parse(localStorage.getItem("user"))?.data?.user?.user_id,
        },
        (response) => {
          // Handle the response from the server, if needed

        }
      );
    });
    socket.on("message", (newMessage) => {
      console.log("🚀 ~ socket.on ~ message:", newMessage)

      toast.info(`${newMessage?.user?.name} sent you a message`)

    });
  }, [])

  
  return (
    <SidebarContentWrap collapse={collapse}>
      <SidebarBlock collapse={collapse}>
        <SidebarCategory
          title={
            <>
              <span
                style={{ color: "#2584CD", fontSize: "50px", marginTop: "4px" }}
              >
                &bull;
              </span>
              <span> &nbsp; Dashbaord</span>
            </>
          }
          // icon="apartment"
          collapse={collapse}
        >
          {!collapse && (
            <SidebarLink
              // title="Dashboard"
              title={
                <>
                  <span
                    style={{ color: "white", fontSize: "50px", marginTop: "4px" }}
                  >
                    &bull;
                  </span>
                  <span> &nbsp; Dashboard</span>
                </>
              }
              // icon="home"
              route="/dashboard"
              onClick={onClick}
            />
          )}
          {!collapse && (
            <SidebarLink
              // title="Dashboard"
              title={
                <>
                  <span
                    style={{ color: "white", fontSize: "50px", marginTop: "4px" }}
                  >
                    &bull;
                  </span>
                  <span> &nbsp; Target Financial </span>
                </>
              }
              // icon="home"
              route="/target-financial"
              onClick={onClick}
            />
          )}
        </SidebarCategory>

        <SidebarLink
          // title="User Dashboard"
          title={
            <>
              <span
                style={{ color: "#F79413", fontSize: "50px", marginTop: "4px" }}
              >
                &bull;
              </span>
              <span> &nbsp; User Dashboard</span>
            </>
          }
          // icon="users"
          route="/appdashboard"
          onClick={onClick}
        />
        <SidebarLink
          // title="User Dashboard"
          title={
            <>
              <span
                style={{ color: "#F7944", fontSize: "50px", marginTop: "4px" }}
              >
                &bull;
              </span>
              <span> &nbsp; Contracts</span>
            </>
          }
          // icon="users"
          route="/contracts"
          onClick={onClick}
        />

        <SidebarCategory
          // title="Marketplace"
          title={
            <>
              <span
                style={{ color: "#FFC107", fontSize: "50px", marginTop: "4px" }}
              >
                &bull;
              </span>
              <span> &nbsp; Marketplace</span>
            </>
          }
          // icon="apartment"
          collapse={collapse}
        >
          {!collapse && <SidebarLink
            title={
              <>
                <span
                  style={{ color: "#ffff", fontSize: "50px", marginTop: "4px" }}
                >
                  &bull;
                </span>
                <span> &nbsp; All Products</span>
              </>
            }
            route="/all-products"
            onClick={onClick}
          />}

          {!collapse && <SidebarLink
            // title="Stores"
            title={
              <>
                <span
                  style={{ color: "#FFFFFF", fontSize: "50px", marginTop: "4px" }}
                >
                  &bull;
                </span>
                <span> &nbsp; Stores</span>
              </>
            }
            // icon="apartment"
            route="/userstores" // Update the route to include the parent path
            onClick={onClick}
          />}
          {!collapse && <SidebarLink
            // title=" Orders"
            title={
              <>
                <span
                  style={{ color: "#FFFFFF", fontSize: "50px", marginTop: "4px" }}
                >
                  &bull;
                </span>
                <span> &nbsp; Orders</span>
              </>
            }
            // icon="layers"
            route="/userorders" // Update the route to include the parent path
            onClick={onClick}
          />}
          {!collapse && <SidebarLink
            // title="Approvers List"
            title={
              <>
                <span
                  style={{ color: "#FFFFFF", fontSize: "50px", marginTop: "4px" }}
                >
                  &bull;
                </span>
                <span> &nbsp; Approvers List</span>
              </>
            }
            route="/approverslist" // Update the route to include the parent path
            onClick={onClick}
          />}

          {!collapse && <SidebarLink
            // title="Categories"
            title={
              <>
                <span
                  style={{ color: "#FFFFFF", fontSize: "50px", marginTop: "4px" }}
                >
                  &bull;
                </span>
                <span> &nbsp; Categories</span>
              </>
            }
            // icon="book"
            route="/create" // Update the route to include the parent path
            onClick={onClick}
          />}
        </SidebarCategory>
        <SidebarCategory
          // title="Marketplace"
          title={
            <>
              <span
                style={{ color: "#FF2f07", fontSize: "50px", marginTop: "4px" }}
              >
                &bull;
              </span>
              <span> &nbsp; OTC Marketplace</span>
            </>
          }
          // icon="apartment"
          collapse={collapse}
        >
          {!collapse && (
            <SidebarLink
              title={
                <>
                  <span
                    style={{
                      color: "#FFFFFF",
                      fontSize: "50px",
                      marginTop: "4px",
                    }}
                  >
                    &bull;
                  </span>
                  <span> &nbsp; OTC Requests</span>
                </>
              }
              route="/otcmarketplace" // Update the route to include the parent path
              onClick={onClick}
            />
          )}
          {!collapse && (
            <SidebarLink
              title={
                <>
                  <span
                    style={{
                      color: "#FFFFFF",
                      fontSize: "50px",
                      marginTop: "4px",
                    }}
                  >
                    &bull;
                  </span>
                  <span> &nbsp; OTC Marketplace Lists</span>
                </>
              }
              route="/otcmarketplacelists" // Update the route to include the parent path
              onClick={onClick}
            />
          )}
          {!collapse && (
            <SidebarLink
              title={
                <>
                  <span
                    style={{
                      color: "#FFFFFF",
                      fontSize: "50px",
                      marginTop: "4px",
                    }}
                  >
                    &bull;
                  </span>
                  <span> &nbsp; OTC Access Requests</span>
                </>
              }
              route="/accessrequest" // Update the route to include the parent path
              onClick={onClick}
            />
          )}
        </SidebarCategory>
        {/* <SidebarCategory
          // title="Marketplace"
          title={
            <>
              <span
                style={{ color: "#FE$f07", fontSize: "50px", marginTop: "4px" }}
              >
                &bull;
              </span>
              <span> &nbsp; Projects</span>
            </>
          }
          // icon="apartment"
          collapse={collapse}
        >
          {!collapse && (
            <SidebarLink
              title={
                <>
                  <span
                    style={{
                      color: "#FFFFFF",
                      fontSize: "50px",
                      marginTop: "4px",
                    }}
                  >
                    &bull;
                  </span>
                  <span> &nbsp; Project Requests</span>
                </>
              }
              route="/project-requets" // Update the route to include the parent path
              onClick={onClick}
            />
          )}
          {!collapse && (
            <SidebarLink
              title={
                <>
                  <span
                    style={{
                      color: "#FFFFFF",
                      fontSize: "50px",
                      marginTop: "4px",
                    }}
                  >
                    &bull;
                  </span>
                  <span> &nbsp; Project Lists</span>
                </>
              }
              route="/project-lists" // Update the route to include the parent path
              onClick={onClick}
            />
          )}
        </SidebarCategory> */}  

        <SidebarCategory
          // title="Marketplace"
          title={
            <>
              <span
                style={{ color: "#FF8f07", fontSize: "50px", marginTop: "4px" }}
              >
                &bull;
              </span>
              <span> &nbsp; Sub Accounts</span>
            </>
          }
          // icon="apartment"
          collapse={collapse}
        >
          {!collapse && (
            <SidebarLink
              title={
                <>
                  <span
                    style={{
                      color: "#FFFFFF",
                      fontSize: "50px",
                      marginTop: "4px",
                    }}
                  >
                    &bull;
                  </span>
                  <span> &nbsp; Account Requests</span>
                </>
              }
              route="/allsubaccounts" // Update the route to include the parent path
              onClick={onClick}
            />
          )}
          {!collapse && (
            <SidebarLink
              title={
                <>
                  <span
                    style={{
                      color: "#FFFFFF",
                      fontSize: "50px",
                      marginTop: "4px",
                    }}
                  >
                    &bull;
                  </span>
                  <span> &nbsp; Sub Accounts</span>
                </>
              }
              route="/subaccounts" // Update the route to include the parent path
              onClick={onClick}
            />
          )}
        </SidebarCategory>

        {
          <SidebarCategory
            // title="Web App"
            title={
              <>
                <span
                  style={{ color: "#198754", fontSize: "50px", marginTop: "4px" }}
                >
                  &bull;
                </span>
                <span> &nbsp; Web App</span>
              </>
            }
            // icon="apartment"
            collapse={collapse}
          >
            {!collapse && (
              <SidebarLink
                // title="Users"
                title={
                  <>
                    <span
                      style={{
                        color: "#FFFFFF",
                        fontSize: "50px",
                        marginTop: "4px",
                      }}
                    >
                      &bull;
                    </span>
                    <span> &nbsp; Users</span>
                  </>
                }
                // icon="users"
                route="/users" // Update the route to include the parent path
                onClick={onClick}
              />
            )}
            {!collapse && (
              <SidebarLink
                // title="Users"
                title={
                  <>
                    <span
                      style={{
                        color: "#FFFFFF",
                        fontSize: "50px",
                        marginTop: "4px",
                      }}
                    >
                      &bull;
                    </span>
                    <span> &nbsp; Withdraw</span>
                  </>
                }
                // icon="users"
                route="/withdraw-report" // Update the route to include the parent path
                onClick={onClick}
              />
            )}
            {!collapse && (
              <SidebarLink
                // title="Users"
                title={
                  <>
                    <span
                      style={{
                        color: "#FFFFFF",
                        fontSize: "50px",
                        marginTop: "4px",
                      }}
                    >
                      &bull;
                    </span>
                    <span> &nbsp; Internal Transfer</span>
                  </>
                }
                // icon="users"
                route="/internal-transfer" // Update the route to include the parent path
                onClick={onClick}
              />
            )}
            {!collapse && (
              <SidebarLink
                // title="Custodiy Wallet"
                title={
                  <>
                    <span
                      style={{
                        color: "#FFFFFF",
                        fontSize: "50px",
                        marginTop: "4px",
                      }}
                    >
                      &bull;
                    </span>
                    <span> &nbsp; Custodiy Wallet</span>
                  </>
                }
                // icon="book"
                route="/CustodiyWallet"
                onClick={onClick}
              />
            )}

            {!collapse && (
              <SidebarLink
                // title="Fee"
                title={
                  <>
                    <span
                      style={{
                        color: "#FFFFFF",
                        fontSize: "50px",
                        marginTop: "4px",
                      }}
                    >
                      &bull;
                    </span>
                    <span> &nbsp; Fee</span>
                  </>
                }
                // icon="apartment"
                route="/fee" // Update the route to include the parent path
                onClick={onClick}
              />
            )}
            {!collapse && (
              <SidebarLink
                // title="Fee"
                title={
                  <>
                    <span
                      style={{
                        color: "#FFFFFF",
                        fontSize: "50px",
                        marginTop: "4px",
                      }}
                    >
                      &bull;
                    </span>
                    <span> &nbsp; News</span>
                  </>
                }
                // icon="apartment"
                route="/news" // Update the route to include the parent path
                onClick={onClick}
              />
            )}
            {!collapse && (
              <SidebarLink
                // title="Fee"
                title={
                  <>
                    <span
                      style={{
                        color: "#FFFFFF",
                        fontSize: "50px",
                        marginTop: "4px",
                      }}
                    >
                      &bull;
                    </span>
                    <span> &nbsp; Partners</span>
                  </>
                }
                // icon="apartment"
                route="/partners" // Update the route to include the parent path
                onClick={onClick}
              />
            )}
            {!collapse && (
              <SidebarLink
                title={
                  <>
                    <span
                      style={{
                        color: "#FFFFFF",
                        fontSize: "50px",
                        marginTop: "4px",
                      }}
                    >
                      &bull;
                    </span>
                    <span> &nbsp; Tokens</span>
                  </>
                }
                route="/tokens"
                onClick={onClick}
              />
            )}
            {/* {!collapse && (
              <SidebarLink
                // title="Coin Balance"
                title={
                  <>
                    <span
                      style={{
                        color: "#FFFFFF",
                        fontSize: "50px",
                        marginTop: "4px",
                      }}
                    >
                      &bull;
                    </span>
                    <span> &nbsp; Coin Balance</span>
                  </>
                }
                // icon="layers"
                route="/coinbalance" // Update the route to include the parent path
                onClick={onClick}
              />
            )} */}
            {!collapse && (
              <SidebarLink
                // title="List Of Transactions"
                title={
                  <>
                    <span
                      style={{
                        color: "#FFFFFF",
                        fontSize: "50px",
                        marginTop: "4px",
                      }}
                    >
                      &bull;
                    </span>
                    <span> &nbsp; List Of Transactions</span>
                  </>
                }
                // icon="book"
                route="/transactions"
                onClick={onClick}
              />
            )}
            {!collapse && (
              <SidebarLink
                // title="List Of Transactions"
                title={
                  <>
                    <span
                      style={{
                        color: "#FFFFFF",
                        fontSize: "50px",
                        marginTop: "4px",
                      }}
                    >
                      &bull;
                    </span>
                    <span> &nbsp; Content for Mobile app</span>
                  </>
                }
                // icon="book"
                route="/mobile-content"
                onClick={onClick}
              />
            )}
            {!collapse && (
              <SidebarLink
                // title="Support Ticket"
                title={
                  <>
                    <span
                      style={{ color: "#ffff", fontSize: "50px", marginTop: "4px" }}
                    >
                      &bull;
                    </span>
                    <span> &nbsp; Add Privacy Notice</span>
                  </>
                }
                // icon="layers"
                route="/privacy-notice"
                onClick={onClick}
              />
            )}
            {!collapse && (
              <SidebarLink
                // title="Support Ticket"
                title={
                  <>
                    <span
                      style={{ color: "#ffff", fontSize: "50px", marginTop: "4px" }}
                    >
                      &bull;
                    </span>
                    <span> &nbsp; Add Cookie Policy</span>
                  </>
                }
                // icon="layers"
                route="/cookie-policy"
                onClick={onClick}
              />
            )}
            {!collapse && (
              <SidebarLink
                // title="Users"
                title={
                  <>
                    <span
                      style={{
                        color: "#FFFFFF",
                        fontSize: "50px",
                        marginTop: "4px",
                      }}
                    >
                      &bull;
                    </span>
                    <span> &nbsp; Migration Report</span>
                  </>
                }
                // icon="users"
                route="/migration-report" // Update the route to include the parent path
                onClick={onClick}
              />
            )}
            {!collapse && (
              <SidebarLink
                // title="Users"
                title={
                  <>
                    <span
                      style={{
                        color: "#FFFFFF",
                        fontSize: "50px",
                        marginTop: "4px",
                      }}
                    >
                      &bull;
                    </span>
                    <span> &nbsp; Notify Users</span>
                  </>
                }
                // icon="users"
                route="/notify-users" // Update the route to include the parent path
                onClick={onClick}
              />
            )}
          </SidebarCategory>
        }
        {/* <SidebarLink
        // title="Support Ticket"
        title={
          <>
            <span
              style={{ color: "#4E0903", fontSize: "50px", marginTop: "4px" }}
            >
              &bull;
            </span>
            <span> &nbsp; Virtual Wallet</span>
          </>
        }
        // icon="layers"
        route="/virtualwallet"
        onClick={onClick}
      /> */}

        <SidebarLink
          // title="Support Ticket"
          title={
            <>
              <span
                style={{ color: "#2B4945", fontSize: "50px", marginTop: "4px" }}
              >
                &bull;
              </span>
              <span> &nbsp; Support Ticket</span>
            </>
          }
          // icon="layers"
          route="/Support"
          onClick={onClick}
        />

        <SidebarLink
          // title="Chat"
          title={
            <>
              <span
                style={{ color: "#8884D8", fontSize: "50px", marginTop: "4px" }}
              >
                &bull;
              </span>
              <span> &nbsp; Chat</span>
            </>
          }
          // icon="bubble"
          route="/Chat"
          onClick={onClick}
        />
      </SidebarBlock>
    </SidebarContentWrap>
  )
}

SidebarContent.propTypes = {
  onClick: PropTypes.func,
  collapse: PropTypes.bool,
};

SidebarContent.defaultProps = {
  collapse: false,
  onClick: () => { },
};

export default SidebarContent;

// region STYLES

const SidebarContentWrap = styled.div`
  height: 100%;
  overflow: auto;
  padding-top: 0;

  & > div:last-child {
    width: 4px !important;

    div {
      transition: height 0.3s;
      opacity: 0.52;
    }
  }

  @media screen and (min-width: 576px) {
    padding-top: 15px;

    ${(props) =>
    props.collapse &&
    `
      width: 55px;
      overflow: visible !important;
      transition: width 0.3s;
    `}
  }
`;

const SidebarBlock = styled.ul`
  padding: 15px 0;
  border-bottom: 1px solid ${colorBorder};
  list-style-type: none;

  &:last-child {
    border: none;
  }

  @media screen and (min-width: 576px) {
    ${(props) =>
    props.collapse &&
    `
      & > li > a,
      & > li > button {
        overflow: hidden;
        width: 55px;
        background: ${colorBackground(props)};
        
        span:last-of-type {
          opacity: 0;
          transition: 0.3s;
        }
  
        ${SidebarLinkTitle} {
          position: absolute;
          width: 160px;
          ${left(props)}: 70px;
        }
  
        &:hover {
          background: ${colorHover(props)};
        }
      }
      
      & > li:hover > a,
      & > li:hover > button {
        width: 275px;
        
        span {
          opacity: 1;
        }
      }
    `}
  }
`;
// endregion
