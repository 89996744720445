import Modal from "react-bootstrap/Modal";
import styled from "styled-components";
import { Button } from "../../../shared/components/Button";
import { deleteRecord } from "../../../utils/services/user.service";
import { toast } from "react-toastify";
import { Ring } from "@uiball/loaders";
import { useState } from "react";

const DeleteRecordModal = ({ show, hide, recordId, reFetch }) => {
    const [isLoading, setIsLoading] = useState(false);

    const handleDelete = async () => {
        setIsLoading(true)
        try {
            const res = await deleteRecord({record_id:recordId._id});  // Ensure correct API call
            toast.success(res.data.Message);
            hide();
            reFetch();
            setIsLoading(false)
        } catch (error) {
            toast.error(error?.response?.data.Message || "Failed to delete record");
            setIsLoading(false)
        }
    };

    return (
        <Modal size="md" centered show={show} onHide={hide}>
            <div
                className="loader-overlay"
                style={{
                    display: isLoading ? "flex" : "none",
                    justifyContent: "center",
                    alignItems: "center",
                    position: "fixed",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    background: "rgba(255, 255, 255, 0.8)",
                    zIndex: 999,
                }}
            >
                <Ring size={65} color="#00897B" />
            </div>
            <ModalBody>
                <ModalTitle>Are you sure you want to delete this?</ModalTitle>
                <ButtonContainer>
                    <Button variant="outline-danger" onClick={handleDelete}>
                        Yes
                    </Button>
                    <Button variant="outline-primary" onClick={hide}>
                        No
                    </Button>
                </ButtonContainer>
            </ModalBody>
        </Modal>
    );
};

export default DeleteRecordModal;

const ModalBody = styled.div`
  padding: 2rem;
  text-align: center;
`;

const ModalTitle = styled.h2`
  margin-bottom: 1.5rem;
  font-size: 18px;
  font-weight: bold;
  color: #333;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-around;
  margin-top: 1.5rem;
`;
