import React, { useEffect, useState } from "react";
// import Modal from "@mui/material/Modal";
import { Modal } from "@mui/material";
import { Modal as BootstrapModal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import TitleCard from "../AddSafebox/TitleCard";
import { Row, Col, Button, Container, Form } from "react-bootstrap";
import { Ring } from "@uiball/loaders";
import {
  getUserStores,
  updateStoreStatus,
  rejectPendingStore_admin,
  approvedPendingStore_admin,
  getCategory,
  changeStoreCategory,
  getUserInfo1,
} from "../../../utils/services/user.service";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import StoreModal from "./StoreModal";
import "./table.css";
import Tfaaa from "../../Account/MyAccount/Tfaaa";
import { getStatusBadge } from "../../../redux/constants/constans";

const UserStores = () => {
  const itemsPerPage = 20;
  const [StoreData, SetStoreData] = useState([]);
  const [storeToUpdate, setStoreToUpdate] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [storeId, setStoreId] = useState(null);
  const [categories, setCategories] = useState([]); // State to store category data
  const [selectedCategoryId, setSelectedCategoryId] = useState("");
  const [storeStatus, setstoreStatus] = useState("");
  const [selectedCategory, setSelectedCategory] = useState();
  const [selectedCategoryStore, setSelectedCategoryStore] = useState();
  const [selectedCategoryForStore, setSelectedCategoryForStore] = useState({});

  function getStatusColorClass(status) {
    switch (status) {
      case "pending":
        return "text-yellow-500";
      case "approved":
        return "text-green-500";
      case "rejected":
        return "text-red-500";
      case "deactivated":
        return "text-gray-500";
      default:
        return "";
    }
  }



  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [openCategory, setOpenCategory] = useState(false);
  const [approveOpen, setapproveOpen] = useState(false);
  const [rejectOpen, setrejectOpen] = useState(false);
  const [textInput, setTextInput] = useState("");
  const [fileInput, setFileInput] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedStore, setSelectedStore] = useState(null);
  const [idQuery, setIdQuery] = useState("");
  const [emailQuery, setEmailQuery] = useState("");
  const [nameQuery, setNameQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [selectedoption, setoptionSelect] = useState("all");
  const [selectedoptionorder, setoptionSelectorder] = useState("all");
  const [TFA, setTFA] = useState(false);
  const userData = JSON.parse(sessionStorage.getItem("user"));
  const usermail = userData?.data?.user?.email;
  const [tfaUrl, setTfaUrl] = useState(false);
  const [tfaEmail, setTfaEmail] = useState("");
  const [showTfaModal, setShowTfaModal] = useState(false);
  const [otpbtn, setOtpbtn] = useState(true);
  const [CategoryChange, setCategoryChange] = useState(false);
  const Ava = `/img/avat.png`;

  const renderTfaModal = () => {
    return (
      <BootstrapModal
        show={showTfaModal}
        onHide={() => setShowTfaModal(false)}
        centered
        className="dark:bg-gray-800"
      >
        <BootstrapModal.Header
          closeButton
          className="dark:border-gray-700 bg-dark text-white"
        >
          <BootstrapModal.Title>TFA Modal</BootstrapModal.Title>
        </BootstrapModal.Header>
        <BootstrapModal.Body className="dark:text-white bg-dark">
          <Tfaaa
            tfaEmail={tfaEmail}
            tfaUrl={tfaUrl}
            onDataFromChild={handleDataFromChild}
          />
        </BootstrapModal.Body>
        <BootstrapModal.Footer className="dark:bg-gray-800 bg-dark dark:border-t dark:border-gray-700">
          <Button variant="secondary" onClick={() => setShowTfaModal(false)}>
            Close
          </Button>
        </BootstrapModal.Footer>
      </BootstrapModal>
    );
  };

  const handleDataFromChild = (data) => {
    sessionStorage.setItem("data", data);
    if (data === true) {
      setShowTfaModal(false);
      if (storeStatus === "approved") {
        showApprovalModal();
      } else if (storeStatus === "rejected") {
        showRejectionModal();
      } else if (CategoryChange === true) {
        setOpenCategory(true);
      } else {
        showConfirmationModal();
      }
      // Additional lines, if needed
      // setIsModalOpen(false);
      // setTFA(false);
    } else {
      // setOtpbtn(false);
      setTfaUrl(true);
      setTfaEmail(userData.data.user.email);
      // setIsModalOpen(true);
    }
  };


  useEffect(() => {
    const storedData = sessionStorage.getItem("data");
    if (storedData === "true") {
      handleDataFromChild(false);
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const requestData = {
        email: usermail,
      };

      try {
        const response = await getUserInfo1(requestData);

        if (response.status === 200) {
          setTFA(response.data.User.TFA);
        } else {
          console.error("getUserInfo1 failed:", response);
        }
      } catch (error) {
        console.error("Error getUserInfo1:", error);
      }
    };

    // Initial data fetch
    fetchData();

    // Set up interval to fetch data every second
    // const intervalId = setInterval(() => {
    fetchData();
    // }, 1000);

    // // Cleanup function to clear the interval when the component is unmounted
    // return () => {
    //   clearInterval(intervalId);
    // };
  }, [usermail, TFA]);

  useEffect(() => {
    const UserStores = async () => {
      try {
        setIsLoading(true);
        const payload = {
          limit: itemsPerPage,
          page_number: currentPage,
          store_status: selectedoptionorder,
          store_name: emailQuery,
          // store_id:"",
          store_category_id: selectedCategoryId,
          // approver_status:"",
        };
        const response = await getUserStores(payload);
        //console.log("response", response);
        if (response.data.result) {
          SetStoreData(response.data.result);

          const totalUsers = response.data.count;
          //console.log("totalUsers", totalUsers);

          const pages = Math.ceil(totalUsers / itemsPerPage);
          //console.log("pages", pages);

          setPageCount(pages);
          setIsLoading(false);
        } else {
          console.error("Not Found");
          setIsLoading(false);
          SetStoreData([]);
          toast.error("Error fetching store data:");
        }
      } catch (error) {
        SetStoreData([]);
        setIsLoading(false);
        // toast.error("Error fetching store data:");
        console.error("Error fetching store data:", error);
      }
    };

    UserStores();
  }, [currentPage, emailQuery, selectedoptionorder, selectedCategoryId]);

  useEffect(() => {
    const getCategorys = async () => {
      try {
        const response = await getCategory(); // Assuming this is your API call
        if (response.data.category) {
          setCategories(response.data.category);
        } else {
          //console.log("category error");
        }
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    getCategorys();
  }, []);
  const handleConfirm = () => {
    // Perform your action here
    hideModal();

    // Now, proceed with the status change action
    if (storeToUpdate && storeId) {
      const payload = {
        store_id: storeId,
        new_status: storeToUpdate,
      };
      const payload2 = {
        storeId: storeId,
      };

      //console.log("payload", payload);

      if (storeToUpdate === "rejected") {
        rejectPendingStore_admin(payload2)
          .then((response) => {
            // Update StoreData state with the response data
            SetStoreData((prevData) => {
              const updatedData = prevData.map((store) => {
                if (store._id === storeId) {
                  return {
                    ...store,
                    status: storeToUpdate,
                  };
                }
                return store;
              });
              return updatedData;
            });
            //console.log("Reject store response:", response);
          })
          .catch((error) => {
            console.error("Error rejecting store:", error);
          });
      } else {
        updateStoreStatus(payload)
          .then((response) => {
            // Update StoreData state with the response data
            SetStoreData((prevData) => {
              const updatedData = prevData.map((store) => {
                if (store._id === storeId) {
                  return {
                    ...store,
                    status: storeToUpdate,
                  };
                }
                return store;
              });
              return updatedData;
            });
            //console.log("Update status response:", response);
          })
          .catch((error) => {
            console.error("Error updating store status:", error);
          });
      }
    }
  };

  const handleEyeButtonClick = (store) => {
    setSelectedStore(store); // Step 3
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    hideModal();
    setOpenCategory(false);
  };

  const handleClose = () => {
    hideModal();
    setOpenCategory(false);
  };
  const showConfirmationModal = () => {
    setOpen(true);
  };
  const showApprovalModal = () => {
    setapproveOpen(true);
  };
  const showRejectionModal = () => {
    setrejectOpen(true);
  };
  const hideModal = () => {
    setOpen(false);
    setapproveOpen(false);
    setrejectOpen(false);
  };

  function handleSubmit() {
    if (textInput.trim() === "") {
      toast.error("Approver comments is required.");
      return;
    }

    if (!fileInput) {
      toast.error("File upload is required.");
      return;
    }
    if (storeToUpdate && storeId) {
      hideModal();
      const formData = new FormData();

      //console.log("formData", formData);

      formData.append("rejection_reason", textInput);
      formData.append("File", fileInput);
      formData.append("store_id", storeId);

      if (storeToUpdate === "rejected") {
        setIsLoading(true);
        rejectPendingStore_admin(formData)
          .then((response) => {
            toast.success(response.data.Message);
            setTextInput("");
            setFileInput(null);

            SetStoreData((prevData) => {
              const updatedData = prevData.map((store) => {
                if (store._id === storeId) {
                  return {
                    ...store,
                    status: storeToUpdate,
                  };
                }
                return store;
              });
              return updatedData;
            });
            setIsLoading(false);
            //console.log("Reject store response:", response);
          })
          .catch((error) => {
            console.error("Error rejecting store:", error);
          });
      } else if (storeToUpdate === "approved") {
        setIsLoading(true);
        const approvedFormData = new FormData();
        //console.log("approvedFormData", approvedFormData);
        approvedFormData.append("approver_reason", textInput);
        approvedFormData.append("File", fileInput);
        approvedFormData.append("store_id", storeId);

        approvedPendingStore_admin(approvedFormData)
          .then((response) => {
            //console.log("response>>>.", response.data);
            toast.success(response.data.Message);
            setTextInput("");
            setFileInput(null);
            SetStoreData((prevData) => {
              const updatedData = prevData.map((store) => {
                if (store._id === storeId) {
                  return {
                    ...store,
                    status: storeToUpdate,
                  };
                }
                return store;
              });
              return updatedData;
            });
            setIsLoading(false);
            //console.log("Approve store response:", response);
          })
          .catch((error) => {
            toast.error(error);
            console.error("Error approving store:", error);
          });
      }
    }
  }

  const handleStatusChange = (event, storeId) => {
    const newStatus = event.target.value;
    //console.log("newStatus", newStatus);
    //console.log("storeId", storeId);

    setstoreStatus(newStatus)
    setStoreToUpdate(newStatus);
    setStoreId(storeId);

    if (TFA) {
      setTfaEmail(userData.data.user.email);
      setTfaUrl(true);
      setShowTfaModal(true);
    } else if (newStatus === "approved") {
      showApprovalModal();
    } else if (newStatus === "rejected") {
      showRejectionModal();
    } else {
      showConfirmationModal();
    }
  };

  const handleApplyClick = async () => {
    try {
      setIsLoading(true);
      const payload = {
        store_status: selectedoptionorder,
        store_name: emailQuery,
        limit: itemsPerPage,
        page_number: currentPage,
        store_category_id: selectedCategoryId,
      };

      const response = await getUserStores(payload);
      //console.log('response>>>>>>>',response)
      SetStoreData(response.data.result);

      const totalUsers = response.data.count;

      const pages = Math.ceil(totalUsers / itemsPerPage);
      //console.log('pages',pages)

      setPageCount(pages);
      //console.log('response', response);
      setIsLoading(false);
      // If you want to handle the results further, you can do so here.
    } catch (error) {
      setIsLoading(false);
      SetStoreData([]);
      // toast.error("Store Not Found ")
      console.error("Error fetching data:", error);
    }
  };

  const UserStores = async () => {
    try {
      setIsLoading(true);
      const payload = {
        limit: itemsPerPage,
        page_number: currentPage,
        store_status: "all",
        store_name: "",
        store_category_id: "",
        // approver_status:"",
      };
      const response = await getUserStores(payload);
      //console.log("response", response);
      if (response.data.result) {
        SetStoreData(response.data.result);

        const totalUsers = response.data.count;
        //console.log("totalUsers", totalUsers);

        const pages = Math.ceil(totalUsers / itemsPerPage);
        //console.log("pages", pages);

        setPageCount(pages);
        const selectedCategories = {};
        response.data.result.forEach((store) => {
          selectedCategories[store._id] =
            store.categories[0]?.category_name || "";
        });

        // Update the state
        setSelectedCategoryForStore(selectedCategories);
        setIsLoading(false);
      } else {
        console.error("Not Found");
        setIsLoading(false);
        toast.error("Error fetching store data:");
      }
    } catch (error) {
      setIsLoading(false);
      toast.error("Error fetching store data:");
      console.error("Error fetching store data:", error);
    }
  };

  const handleApiChange2 = (event) => {
    const selectedOption = event.target.value;
    //console.log('selectedOption>>>>>',typeof(selectedOption))

    setoptionSelectorder(selectedOption);
    //console.log('setoptionSelect>>>>>', setoptionSelectorder);
  };

  const handleCategoryChange = (event) => {
    setSelectedCategoryId(event.target.value);
  };
  const customPaddingStyle = {
    padding: " 6px 4px", // Top, Right, Bottom, Left
  };

  const handleCategoryupdate = async () => {
    const payload = {
      category_id: selectedCategory,
      store_id: selectedCategoryStore,
    };
    setIsLoading(true);
    try {
      const response = await changeStoreCategory(payload);
      //console.log('responseupdatecategory',response)
      if (response.data) {
        UserStores();
        toast.success(response.data.Message);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div
        className="loader-overlay"
        style={{
          display: isLoading ? "flex" : "none",
          justifyContent: "center",
          alignItems: "center",
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          zIndex: 999,
        }}
      >
        <Ring size={65} color="#00897B" />
      </div>
      <TitleCard title="" topMargin="mt-2">
        <h3 className="mb-4">Apply Filters</h3>
        <div className="lg:flex lg:space-x-4  sm:w-full">
          <div className="flex flex-col lg:w-1/4 mb-4 lg:mb-0 ">
            <select
              id="categoryDropdown"
              className=" rounded-lg mr-2 mb-1 lg:mb-1"
              onChange={handleCategoryChange}
              style={customPaddingStyle}
              value={selectedCategoryId}
            >
              <option value="">Select category</option>
              {categories.length > 0 &&
                categories.map((category) => (
                  <option key={category._id} value={category._id}>
                    {category.category_name}
                  </option>
                ))}
            </select>
          </div>

          <div className="flex flex-col lg:w-1/4 mb-4 lg:mb-0 sm:w-full">
            <select
              onChange={handleApiChange2}
              className=" rounded-lg mr-2"
              style={customPaddingStyle}
              id="filters"
            >
              <option selected={selectedoptionorder === "all"} value="all">
                Select Status
              </option>
              <option
                selected={selectedoptionorder === "pending"}
                value="pending"
              >
                Pending
              </option>
              <option
                selected={selectedoptionorder === "approved"}
                value="approved"
              >
                Approved
              </option>
              <option
                selected={selectedoptionorder === "rejected"}
                value="rejected"
              >
                Rejected
              </option>
              <option
                selected={selectedoptionorder === "deactivated"}
                value="deactivated"
              >
                deactivated
              </option>
            </select>
          </div>

          <div className="flex flex-col items-start ml-1 w-auto sm:mb-4">
            <input
              type="text"
              placeholder="Store Name..."
              className="rounded-lg p-1 w-full"
              value={emailQuery}
              onChange={(e) => setEmailQuery(e.target.value)}
            />
          </div>

          {/* <button className="w-16 h-7 bg-green-500 text-sm text-white  ml-2 mb-3 rounded-lg" onClick={handleApplyClick}>Apply</button>
      <button className="w-16 h-7 bg-red-500 text-sm text-white  ml-2 mb-3 rounded-lg" onClick={reset}>Reset</button> */}
        </div>

        <div className="overflow-x-auto w-full">
          <table className="tbody table w-full ">
            <thead>
              <tr>
                <th>Store Logo</th>
                <th>Approver Name</th>
                {/* <th>Current Category</th> */}
                <th>Update Category</th>
                <th>Current Status</th>
                <th>Update Status</th>
                <th>More Details</th>
              </tr>
            </thead>
            <tbody>
              {StoreData.length > 0 ? (
                StoreData.map((store) => (
                  <tr key={store._id}>
                    <td>
                      <div className="flex items-center space-x-3">
                        <div className="avatar w-12 h-12 rounded-md">
                          {/* <div className="mask mask-circle w-12 h-12"> */}
                          <img
                            src={store?.storeLogo}
                            alt="Avatar"
                            className=" rounded-md"
                          />
                          {/* </div> */}
                        </div>
                        <div>
                          <div className="">{store.storeName}</div>
                        </div>
                      </div>
                    </td>
                    <td>

                      <div className="flex flex-row pr-2 items-center gap-3 ">
                        <img className="flex h-[44px]  w-[44px] rounded-3xl" src={store?.approver_name[0]?.image || Ava} />
                        <span>
                          {store?.approver_name[0]?.name}
                        </span>
                      </div>
                    </td>
                    {/* <td>
                    {store.categories.length > 0
                        ? store.categories[0].category_name
                        : ""}
                    </td> */}
                    <td>
                      {/* {store.categories.length > 0
                        ?  */}
                      <div className=" text-black">
                        {/* <label htmlFor="category">Select a Category:</label> */}
                        <select
                          className="p-1 rounded-lg"
                          // selected={selectedCategory}
                          id="category"
                          placeholder="kjjkjl"
                          name={"jkjljl"}
                          selected={selectedCategoryForStore}
                          value={
                            store.categories.length > 0
                              ? store.categories[0].category_name
                              : ""
                          }
                          onChange={(event) => {
                            setSelectedCategory(event.target.value);
                            setSelectedCategoryStore(store._id);

                            if (TFA) {
                              setCategoryChange(true);
                              setTfaEmail(userData.data.user.email);
                              setTfaUrl(true);
                              setShowTfaModal(true);
                            } else {
                              setOpenCategory(true);
                            }
                          }}
                        >
                          <option disabled selected>
                            {store.categories[0].category_name}
                          </option>
                          {categories.map((category) => (
                            <>
                              <option key={category._id} value={category._id}>
                                {category.category_name}
                              </option>
                            </>
                          ))}
                        </select>
                      </div>
                      {/* : ""} */}
                    </td>
                    <td className="text-center">
                      {getStatusBadge(store.status)}
                    </td>

                    <td>
                      <div className="relative inline-block text-black">
                        <select
                          id={`statusDropdown-${store._id}`}
                          onChange={(e) => handleStatusChange(e, store._id)}
                          value={store.status}
                          className="appearance-none w-full  bg-white border border-gray-300 hover:border-gray-400 px-4 py-1 pr-8 rounded leading-tight focus:outline-none focus:border-blue-500"
                        >
                          <option
                            value="pending"
                            className={getStatusColorClass("pending")}
                          >
                            Pending
                          </option>
                          <option
                            value="approved"
                            className={getStatusColorClass("approved")}
                          >
                            Approved
                          </option>
                          <option
                            value="rejected"
                            className={getStatusColorClass("rejected")}
                          >
                            Rejected
                          </option>
                          <option
                            value="deactivated"
                            className={getStatusColorClass("deactivated")}
                          >
                            Deactivated
                          </option>
                        </select>
                        {/* <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
          <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7"></path>
          </svg>
        </div> */}
                      </div>
                    </td>
                    <td className="flex justify-center items-center">
                      <button
                        className="text-lime-500 hover:underline mt-0.5 mr-16"
                        onClick={() => handleEyeButtonClick(store)}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-6 h-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                          />
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                          />
                        </svg>
                      </button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="6">No Stores Available</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        {pageCount > 1 && (
          <div className="flex items-center justify-center mt-4">
            <button
              onClick={() => setCurrentPage(currentPage - 1)}
              disabled={currentPage === 1}
              className="flex items-center justify-center px-1 h-8 ml-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-l-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
            >
              <span className="sr-only">Previous</span>
              <svg
                className="w-2.5 h-2.5"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 6 10"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M5 1 1 5l4 4"
                />
              </svg>
            </button>
            {/* Pagination links */}
            {pageCount <= 3 ? (
              Array.from({ length: pageCount }).map((_, index) => (
                <button
                  key={index}
                  onClick={() => setCurrentPage(index + 1)}
                  className={`mx-1 py-1 px-2 rounded-md cursor-pointer ${currentPage === index + 1
                    ? "text-blue-600 border-blue-300 bg-blue-50 hover:bg-blue-100"
                    : "text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                    }`}
                >
                  {index + 1}
                </button>
              ))
            ) : (
              <>
                {currentPage > 1 && (
                  <>
                    <button
                      onClick={() => setCurrentPage(1)}
                      className={`mx-1 py-2 px-3 rounded-md cursor-pointer ${currentPage === 1
                        ? "text-blue-600 border-blue-300 bg-blue-50 hover:bg-blue-100"
                        : "text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                        }`}
                    >
                      1
                    </button>
                    {currentPage > 3 && <span className="dots">....</span>}
                  </>
                )}
                {currentPage > 2 && (
                  <button
                    onClick={() => setCurrentPage(currentPage - 1)}
                    className={`mx-1 py-2 px-3 rounded-md cursor-pointer ${currentPage === currentPage - 1
                      ? "text-blue-600 border-blue-300 bg-blue-50 hover:bg-blue-100"
                      : "text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                      }`}
                  >
                    {currentPage - 1}
                  </button>
                )}
                <button
                  className={`mx-1 py-2 px-3 rounded-md cursor-pointer ${currentPage === currentPage
                    ? "text-blue-600 border-blue-300 bg-blue-50 hover:bg-blue-100"
                    : "text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                    }`}
                >
                  {currentPage}
                </button>
                {currentPage < pageCount - 1 && (
                  <button
                    onClick={() => setCurrentPage(currentPage + 1)}
                    className={`mx-1 py-2 px-3 rounded-md cursor-pointer ${currentPage === currentPage + 1
                      ? "text-blue-600 border-blue-300 bg-blue-50 hover:bg-blue-100"
                      : "text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                      }`}
                  >
                    {currentPage + 1}
                  </button>
                )}
                {currentPage < pageCount && (
                  <>
                    {currentPage < pageCount - 2 && (
                      <span className="dots">....</span>
                    )}
                    <button
                      onClick={() => setCurrentPage(pageCount)}
                      className={`mx-1 py-2 px-3 rounded-md cursor-pointer ${currentPage === pageCount
                        ? "text-blue-600 border-blue-300 bg-blue-50 hover:bg-blue-100"
                        : "text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                        }`}
                    >
                      {pageCount}
                    </button>
                  </>
                )}
              </>
            )}
            <button
              onClick={() => setCurrentPage(currentPage + 1)}
              disabled={currentPage === pageCount}
              className="flex items-center justify-center px-1 h-8 leading-tight text-gray-500 bg-white border border-gray-300 rounded-r-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
            >
              <span className="sr-only">Next</span>
              <svg
                class="w-1.5 h-2.5"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 6 10"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="m1 9 4-4-4-4"
                />
              </svg>
            </button>
          </div>
        )}
      </TitleCard>

      {isModalOpen && selectedStore && (
        <StoreModal
          selectedStore={selectedStore}
          closeModal={closeModal}
          isModalOpen={isModalOpen}
        />
      )}

      <Modal
        open={approveOpen}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        className="fixed inset-0 flex items-center justify-center"
      >
        <div className="custom-dialog bg-white bg-opacity-20 w-72 rounded-lg p-4">
          <p className="text-black font-semibold text-lg mb-4">
            {" "}
            Are you sure you want to Approve the Store ?
          </p>
          {/* Text Input Field */}
          <div className="mb-4">
            <label htmlFor="textInput" className="text-gray-600 text-sm">
              Approver Reason
            </label>
            <input
              type="text"
              id="textInput"
              className="w-full border border-gray-300 rounded p-2"
              placeholder="Enter text..."
              value={textInput}
              onChange={(e) => setTextInput(e.target.value)}
              required // Add the required attribute here
            />
          </div>

          {/* Document Upload Field */}
          <div className="mb-4">
            <label htmlFor="fileInput" className="text-gray-600 text-sm">
              Upload Document:
            </label>
            <input
              type="file"
              id="fileInput"
              accept=".pdf, .doc, .docx"
              onChange={(e) => setFileInput(e.target.files[0])}
              className="form-control-file"
              required // Add the required attribute here
            />
          </div>

          <div className="flex justify-end">
            <button
              onClick={handleCancel}
              className="bg-gray-200 hover:bg-gray-300 text-gray-700 py-2 px-4 rounded mr-2"
            >
              No
            </button>
            <button
              onClick={handleSubmit}
              className="bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded"
            >
              Yes
            </button>
          </div>
        </div>
      </Modal>

      <Modal
        open={rejectOpen}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        className="fixed inset-0 flex items-center justify-center"
      >
        <div className="custom-dialog bg-white bg-opacity-20 w-72 rounded-lg p-4">
          <p className="text-black font-semibold text-lg mb-4">
            {" "}
            Are you sure you want to Reject the Store ?
          </p>
          <div className="mb-4">
            <label htmlFor="textInput" className="text-gray-600 text-sm">
              Rejection Reason
            </label>
            <input
              type="text"
              id="textInput"
              className="w-full border border-gray-300 rounded p-2"
              placeholder="Enter text..."
              value={textInput}
              onChange={(e) => setTextInput(e.target.value)}
              required // Add the required attribute here
            />
          </div>

          {/* Document Upload Field */}
          <div className="mb-4">
            <label htmlFor="fileInput" className="text-gray-600 text-sm">
              Upload Document:
            </label>
            <input
              type="file"
              id="fileInput"
              accept=".pdf, .doc, .docx"
              onChange={(e) => setFileInput(e.target.files[0])}
              className="form-control-file"
              required // Add the required attribute here
            />
          </div>

          <div className="flex justify-end">
            <button
              onClick={handleCancel}
              className="bg-gray-200 hover:bg-gray-300 text-gray-700 py-2 px-4 rounded mr-2"
            >
              No
            </button>
            <button
              onClick={handleSubmit}
              className="bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded"
            >
              Yes
            </button>
          </div>
        </div>
      </Modal>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        className="fixed inset-0 flex items-center justify-center"
      >
        {/* <div className="bg-black bg-opacity-50 absolute inset-0"></div> */}
        <div className="custom-dialog bg-white bg-opacity-20 w-72 rounded-lg p-4">
          <p className="text-black font-semibold text-lg mb-4">
            Are you sure you want to change the status?
          </p>
          <div className="flex justify-end">
            <button
              onClick={handleCancel}
              className="bg-gray-200 hover:bg-gray-300 text-gray-700 py-2 px-4 rounded mr-2"
            >
              No
            </button>
            <button
              onClick={handleConfirm}
              className="bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded"
            >
              Yes
            </button>
          </div>
        </div>
      </Modal>

      <Modal
        open={openCategory}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        className="fixed inset-0 flex items-center justify-center"
      >
        {/* <div className="bg-black bg-opacity-50 absolute inset-0"></div> */}
        <div className="custom-dialog bg-white bg-opacity-20 w-72 rounded-lg p-4">
          <p className="text-black font-semibold text-lg mb-4">
            Are you sure you want to change the category of this Store?
          </p>
          <div className="flex justify-end">
            <button
              onClick={handleCancel}
              className="bg-gray-200 hover:bg-gray-300 text-gray-700 py-2 px-4 rounded mr-2"
            >
              No
            </button>
            <button
              onClick={() => {
                handleCategoryupdate();
                setOpenCategory(false);
              }}
              className="bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded"
            >
              Yes
            </button>
          </div>
        </div>
      </Modal>
      {showTfaModal && renderTfaModal()}
    </>
  );
};

export default UserStores;
